import Vue       from 'vue'
import Vuex      from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import groupBy   from 'lodash/groupBy'
import dayjs     from 'dayjs'
import profilesApi from '@/api/v1/profiles'
import facilityProfilesApi from '@/api/v1/facility-profiles'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
var isBetween     = require('dayjs/plugin/isBetween')
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)

const camelcaseKeys = require('camelcase-keys')
const snakecaseKeys = require('snakecase-keys')

const emptyPlayer = {
  details: {
    playerName: '',
    id: 0 
  }
}
const state = () => ({
  playersList: [],
  drilldownPlayer: cloneDeep(emptyPlayer),
  drilldownBookingsDateRange: [
    dayjs().startOf('month').format('YYYY-MM-DD'),
    dayjs().format('YYYY-MM-DD')
  ],
  showingClientType: '',
  sortBy: 'name',
})

const getters = {
  drilldownPlayerId: state => {
    return state.drilldownPlayer.details.id
  },
  drilldownPlayerClientId: state => {
    return state.drilldownPlayer.client.id
  },
  playerNameFromId: state => playerId => {
    var player = state.playersList.find(player => player.id === playerId)
    return (player) ? player.playerName : '?'
  },
  drilldownPastBookings: (state, getters) => {
    return (getters.drilldownPlayerId) ? state.drilldownPlayer.bookings.filter(booking => dayjs(booking.bookingDate).isBefore(new Date(), 'day')) : []
  },
  drilldownUpcomingBookings: (state, getters) => {
    return (getters.drilldownPlayerId) ? state.drilldownPlayer.bookings.filter(booking => dayjs(booking.bookingDate).isSameOrAfter(new Date(), 'day')) : []
  },
  startDate: state => {
    return dayjs(state.drilldownBookingsDateRange[0]).format('YYYY-MM-DD')
  },
  endDate: state => {
    return dayjs(state.drilldownBookingsDateRange[1]).format('YYYY-MM-DD')
  },
  drilldownBookingsFiltered: (state, getters) => {
    return (getters.drilldownPlayerId) 
         ? state.drilldownPlayer.bookings.filter(booking => dayjs(booking.bookingDate).isBetween(getters.startDate, getters.endDate, 'day', '[]'))
         : []
  },
  drilldownPlayerBookings: (state, getters) => {
    return (getters.drilldownPlayerId) ? groupBy(getters.drilldownBookingsFiltered, 'bookingDate') : []
  },
  getField
}

const actions = {
  getPlayers ({ state, commit }) {
    return new Promise(resolve => {
      profilesApi.getPlayerProfiles(state.showingClientType).then(resp => {
        commit('setPlayers', camelcaseKeys(resp.profiles))
        resolve()
      })
    })
  },
  searchPlayerProfiles ({ commit }, searchString) {
    return new Promise(resolve => {
      profilesApi.searchPlayerProfiles(searchString).then(resp => {
        commit('setPlayers', camelcaseKeys(resp.profiles))
        resolve(resp)
      })
    })
  },
  findPlayerProfile ({ commit }, searchString) {
    return new Promise(resolve => {
      profilesApi.findPlayerProfile(searchString).then(resp => {
        commit('setPlayers', camelcaseKeys(resp))
        resolve(resp)
      })
    })
  },
  getPlayerDetails ({ dispatch }, player) {
    return new Promise(resolve => {
      profilesApi.getPlayerProfileDetails(player.id).then(playerwithDetails => { // update to getPlayerProfileDetails when figuring out bookings stuff
        dispatch('selectPlayerToDrilldown', camelcaseKeys(playerwithDetails.profile,  { deep: true }))
        resolve()
      })
    })
  },
  createProfileByManager (context, playerParams) {
    return new Promise(resolve => {
      profilesApi.createProfileByManager(playerParams).then(newPlayer => {
        let newProfile = camelcaseKeys(newPlayer,  { deep: true })
        resolve(newProfile)
      })
    })
  },
  saveEditedProfile ({ rootState, commit }) {
    let profileParams = snakecaseKeys(cloneDeep(rootState.playerProfile.editingProfile))
    return new Promise(resolve => {
      profilesApi.editProfile(profileParams.id, profileParams).then(resp => {
        let editedProfile = camelcaseKeys(resp.profile, {deep: true})
        commit('updateEditedPlayer', editedProfile)
        resolve(editedProfile)
      })
    })
  },
  deleteProfile ({ rootState, commit }) {
    let profileId = rootState.playerProfile.editingProfile.id
    return new Promise(resolve => {
      profilesApi.deletePlayerProfile(profileId).then(() => {
        commit('updateDeletedPlayer', profileId)
        resolve()
      })
    })
  },
  addProfileToClientList (context, facilityProfileParams) {
    return new Promise(resolve => {
      facilityProfilesApi.createFacilityProfile(facilityProfileParams).then((resp) => {
        resolve(resp)
      })
    })
  },
  updateProfileClientTypeOnClientList ({ getters, commit }, facilityProfileParams) {
    return new Promise(resolve => {
      facilityProfilesApi.updateFacilityProfile(getters.drilldownPlayerClientId, facilityProfileParams).then((resp) => {
        commit('updateEditedPlayerClientData', camelcaseKeys(resp.facility_profile, {deep: true}))
        resolve(camelcaseKeys(resp.facility_profile, {deep: true}))
      })
    })
  },
  selectShowingClientType ({ commit }, showingClientType) {
    commit('setShowingClientType', showingClientType)
  },
  selectSortBy ({ commit }, sortBy) {
    commit('setSortBy', sortBy)
  },
  resetDrilldownPlayer ({ commit }) {
    commit('setDrilldownPlayer', cloneDeep(emptyPlayer))
  },
  selectPlayerToDrilldown ({ commit }, player) {
    commit('setDrilldownPlayer', player)
  },
}

const mutations = {
  setPlayers (state, players) {
    state.playersList = players
  },
  setDrilldownPlayer (state, player) {
    state.drilldownPlayer = player
  },
  setShowingClientType (state, clientType) {
    state.showingClientType = clientType
  },
  setSortBy (state, sortBy) {
    state.sortBy = sortBy
  },
  updateEditedPlayer (state, updatedPlayer) {
    let index = state.playersList.findIndex(player => parseInt(player.id) === updatedPlayer.id)
    if (index !== -1) {
      state.playersList.splice(index, 1, updatedPlayer)
      state.drilldownPlayer.details = updatedPlayer
    }
  },
  updateEditedPlayerClientData (state, clientData) {
    state.drilldownPlayer.client = clientData
  },
  updateDeletedPlayer (state, deletedPlayerId) {
    let index = state.playersList.findIndex(player => parseInt(player.id) === deletedPlayerId)
    state.playersList.splice(index, 1)
  },
  updateField
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
