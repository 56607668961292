import base from './base'

const getNewBookingRequests = () => base
  .get('/new_booking_requests/').then(resp => resp.data)

const getMyNewBookingRequests = () => base
  .get('/new_booking_requests/mine').then(resp => resp.data)

const getManagerNewBookingRequests = (newBookingRequestType, newBookingRequestCreatedDate, newBookingRequestPlayDate) => base
  .get('/new_booking_requests/new_booking_requests_we_manage', {
    params: {
      'new_booking_request_type': newBookingRequestType,
      'new_booking_request_created_date': newBookingRequestCreatedDate,
      'new_booking_request_play_date': newBookingRequestPlayDate,
    }}).then(resp => resp.data)

const createNewBookingRequest = (newBookingRequestParams) => base
  .post('/new_booking_requests/', newBookingRequestParams).then(resp => resp.data)

const createAndApproveNewBookingRequest = (newBookingRequestParams) => base
  .post('/new_booking_requests/create_and_approve', newBookingRequestParams).then(resp => resp.data)

const createBlockedBookings = (newBookingRequestParams) => base
  .post('/new_booking_requests/create_blocked_bookings', newBookingRequestParams).then(resp => resp.data)

const createAndReadyForPay = (newBookingRequestParams) => base
  .post('/new_booking_requests/create_and_ready_for_pay', newBookingRequestParams).then(resp => resp.data)

const updateNewBookingRequest = (newBookingRequestId, newBookingRequestParams) => base
  .patch(`/new_booking_requests/${newBookingRequestId}`, newBookingRequestParams).then(resp => resp.data)

const deleteNewBookingRequest = (newBookingRequestId) => base
  .delete(`/new_booking_requests/${newBookingRequestId}`).then(resp => resp.data)

const getSingleNewBookingRequest = (newBookingRequestId) => base
  .get(`/new_booking_requests/${newBookingRequestId}`).then(resp => resp.data)

export default {
  getNewBookingRequests:        getNewBookingRequests,
  getMyNewBookingRequests:      getMyNewBookingRequests,
  getManagerNewBookingRequests: getManagerNewBookingRequests,
  getSingleNewBookingRequest:   getSingleNewBookingRequest,
  createNewBookingRequest:      createNewBookingRequest,
  createBlockedBookings:        createBlockedBookings,
  createAndApproveNewBookingRequest: createAndApproveNewBookingRequest,
  createAndReadyForPay:         createAndReadyForPay,
  updateNewBookingRequest:      updateNewBookingRequest,
  deleteNewBookingRequest:      deleteNewBookingRequest,
}
