<template>
  <div class='lg:flex lg:flex-row lg:items-stretch h-screen' style='background-color: #FCFCFC;'>
    <div class='lg:flex-grow-0 lg:flex-shrink-0 w-full lg:w-96 p-4 lg:p-8 lg:border-r border-gray-100 shadow-xl text-left'>
      <section class='mt-4'>
        <h2 class='uppercase text-base font-semibold'>고객 검색</h2>
        <input
          type='textbox'
          class='block border text-base py-2 px-4 rounded w-1/2 lg:w-full mt-2'
          placeholder='이름/전화번호'
          v-model='searchPlayersInput'>
      </section>
      <section class='mt-4'>
        <h2 class='uppercase text-base font-semibold'>고객 타입</h2>
        <select class='block border-gray-200 text-base py-2 px-4 rounded w-1/2 lg:w-full mt-2'
                v-model='showingClientType'
                @change='selectShowingClientType($event.target.value)'>
          <option value=''>전체</option>
          <option value='regular'>정기회원</option>
          <option value='general'>일반회원</option>
        </select>
      </section>
      <section class='mt-4'>
        <h2 class='uppercase text-base font-semibold'>정렬</h2>
        <select class='block border-gray-200 text-base py-2 px-4 rounded w-1/2 lg:w-full mt-2'
                @change='selectSortBy($event.target.value)'>
          <option value='name'>이름순</option>
          <option value='createdAt'>가입날짜순</option>
        </select>
      </section>
    </div>
    <div class='overflow-x-auto overflow-y-auto lg:flex-grow text-left p-4 lg:p-8'>
      <table class='w-full text-sm mt-4 lg:mt-8 mx-0 lg:mx-8'>
        <thead>
          <tr class='border-b border-gray-200 uppercase text-gray-600 font-semibold'>
            <th class='px-2 pb-1 w-40 border-r whitespace-pre-line'>
              이름
            </th>
            <th class='px-2 pb-1 border-r'>
              전화번호
            </th>
            <th class='px-2 pb-1 border-r'>
              가입날짜
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='player in sortedPlayerlist'
            :key='`player-list-player-${player.id}`'
            class='border-b border-gray-100 hover:bg-gray-200 cursor-pointer'
            :class='selectedPlayerStyle(player.id)'
            @click='openPlayerDetails(player)'>
            <td class='px-2 py-1 border-r'>
              <span class='whitespace-nowrap'>{{player.name}}</span>
            </td>
            <td class='px-2 py-1 border-r whitespace-pre-line'>
              {{player.phoneNumber}}
            </td>
            <td class='px-2 py-1 border-r whitespace-pre-line'>
              {{ playerCreatedAtString(player) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions }   from 'vuex'
import StringHelpers                          from '@/utils/string-helpers'

export default {
  name: 'ManagePlayers',
  components: {
  },
  data () {
    return {
      timeTillLastLesson: 'anytime',
      searchPlayersInput: ''
    }
  },
  watch: {
    searchPlayersInput: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal) {
            this.searchPlayerProfiles(newVal)
          } else { // when search string is empty
            this.getPlayers(this.showingClientType) 
          }
        }
      }, 
      immediate: true,
    },
    showingClientType: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getPlayers(newVal)
        }
      }, 
      immediate: true,
    }
  },
  computed: {
    ...mapState('managePlayers', [
      'playersList',
      'showingClientType',
      'sortBy'
    ]),
    ...mapGetters('managePlayers', [
      'drilldownPlayerId'
    ]),
    sortedPlayerlist () {
      return this.playersList.slice().sort((a,b) => {
        if (this.sortBy === 'name') {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        } else if (this.sortBy === 'createdAt') {
          if (a.createdAt < b.createdAt) {
            return 1
          }
          if (a.createdAt > b.createdAt) {
            return -1
          }
          return 0
        } else {
          return this.playersList
        }
      })
    },
  },
  methods: {
    ...mapActions([
      'sidepanelOpen',
    ]),
    ...mapActions('managePlayers', [
      'selectShowingClientType',
      'selectSortBy',
      'getPlayers',
      'searchPlayerProfiles',
      'getPlayerDetails',
    ]),
    openPlayerDetails (player) {
      this.getPlayerDetails(player).then(() => {
        this.sidepanelOpen({ drilldownComponent: 'player-drilldown' })
      })
    },
    selectedPlayerStyle (playerId) {
      return (playerId === this.drilldownPlayerId) ? 'bg-blue-100' : ''
    },
    playerCreatedAtString (player) {
      return StringHelpers.dateString(player.createdAt)
    },
  },
  mounted () {
  },
}
</script>
