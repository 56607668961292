<template>
  <nav class='nav-container'>
    <div>
      <router-link :to='{ name: "ManageNewRequests" }'
        class='navlink'>
        <bell-icon class='block mx-auto h-4' style='margin-top: -2px;' />
        <h4 class='text-xs mt-1 text-gray-400'>예약관리</h4>
      </router-link>
      <router-link :to='{ name: "ManageBookings" }'
        class='navlink'>
        <calendar-icon class='block mx-auto h-4' style='margin-top: -2px;' />
        <h4 class='text-xs mt-1 text-gray-400'>예약현황</h4>
      </router-link>
      <router-link :to='{ name: "ManagePlayers" }'
        class='navlink'>
        <user-group-icon class='block mx-auto h-4' style='margin-top: -2px;' />
        <h4 class='text-xs mt-1 text-gray-400'>고객</h4>
      </router-link>
      <router-link :to='{ name: "ManageReport" }' v-if='false'
        class='navlink'>
        <chart-bar-icon class='block mx-auto h-4' style='margin-top: -2px;' />
        <h4 class='text-xs mt-1 text-gray-400'>리포트</h4>
      </router-link>
      <router-link :to='{ name: "ManageRequests" }' v-if='false'
        class='navlink'>
        <bell-icon class='block mx-auto h-4' style='margin-top: -2px;' />
        <h4 class='text-xs mt-1 text-gray-400'>자동예약</h4>
      </router-link>
      <router-link :to='{ name: "ManageSettings" }'
        class='navlink'>
        <cog-icon class='block mx-auto h-4' style='margin-top: -2px;' />
        <h4 class='text-xs mt-1 text-gray-400'>설정</h4>
      </router-link>
    </div>
    <button 
      class='navlink'
      @click='logout'>
      <logout-icon class='block mx-auto h-4' style='margin-top: -2px;' />
      <h4 class='mt-1 text-gray-400' style='font-size:0.7rem'>로그아웃</h4>
    </button>
  </nav>
</template>

<script>
import { mapActions } from 'vuex'
import ssoSessionsApi       from '@/api/v1/users/api-sso-sessions.js'
import { BellIcon, CalendarIcon, CogIcon, UserGroupIcon, ChartBarIcon, LogoutIcon } from '@vue-hero-icons/outline'

export default {
  name: 'NavMenuManager',
  components: {
    BellIcon,
    ChartBarIcon,
    CalendarIcon,
    CogIcon,
    UserGroupIcon,
    LogoutIcon
  },
  methods: {
    ...mapActions([
      'hideContentOnMobile',
      'clearLocalStorageData',
    ]),
    logout () {
      ssoSessionsApi.logout().then(() => {
        this.clearLocalStorageData()
        this.$router.push({name: 'Login'})
      })
    }
  }
}
</script>

<style  lang='scss' scoped>
.navlink {
  @apply px-4 py-2 lg:py-3 mx-2 lg:mx-2 lg:mb-8 rounded-md inline-block lg:block text-gray-800 border border-transparent text-gray-800;
}
.navlink:hover {
  @apply border-indigo-100 bg-white;
}
.navlink.router-link-active {
  @apply text-gray-700 font-semibold shadow-inner border-gray-100;
  background-color: #FFFFFFEE;
}

.navlink.router-link-active h4 {
  @apply text-blue-700 font-semibold;
}


@media print {
  nav.nav-container {
    display: none;
  }
}
</style>
