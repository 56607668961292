<template>
  <div>
    <h1 class='text-3xl font-semibold'>{{drilldownPlayer.details.name}}</h1>
    <div class='flex flex-row justify-start items-center gap-x-2'>
      <div class='text-sm'>
        {{drilldownPlayer.details.phoneNumber}}
      </div>
      <button
        @click='openEditingPlayerProfile'
        class='block text-sm text-gray-500 hover:text-gray-800 px-2 py-1 rounded-md bg-transparent hover:bg-gray-300'>
        수정
      </button>
      <button
        @click='deletePlayerProfile'
        class='block text-sm text-gray-500 hover:text-gray-800 px-2 py-1 rounded-md bg-transparent hover:bg-gray-300'>
        삭제
      </button>
    </div>
    <el-dialog
      :visible.sync='showEditProfileDialog'
      custom-class='w-5/6 lg:w-96'
      top='20vh'
      :append-to-body='true'>
      <h1 class='text-left font-semibold text-xl'>고객정보 변경</h1>
      <profile-form class='my-8' />
      <button
        class='profile-edit-button w-full mb-3'
        @click='saveProfile'>
        수정
      </button>
      <button
        @click='closeProfileEditing'>
        취소
      </button>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ProfileForm from '@/components/profiles/ProfileForm'

export default {
  name: 'PlayerDrilldownSummary',
  components: {
    ProfileForm,
  },
  data () {
    return {
      showEditProfileDialog: false,
    }
  },
  watch: {
    showEditProfileDialog: {
      handler: function (newVal) {
        if (!newVal) {
          this.resetEditingProfile()
        }
      }
    }
  },
  computed: {
    ...mapState('managePlayers', [
      'drilldownPlayer',
      'showingClientType'
    ]),
    ...mapState('playerProfile', [
      'editingProfile'
    ])
  },
  methods: {
    ...mapActions('playerProfile', [
      'editThisProfile',
      'resetEditingProfile',
    ]),
    ...mapActions('managePlayers', [
      'getPlayers',
      'saveEditedProfile',
      'updateProfileClientTypeOnClientList',
      'deleteProfile'
    ]),
    ...mapActions([
      'sidepanelClose'
    ]),
    closeProfileEditing () {
      this.showEditProfileDialog = false
    },
    saveProfile () {
      this.saveEditedProfile().then(() => {
        let facilityProfileParams = {
          facility_profile: {
            client_type: this.editingProfile.client.clientType
          }
        }
        this.updateProfileClientTypeOnClientList(facilityProfileParams).then(() => {
          this.getPlayers(this.showingClientType)
          this.closeProfileEditing()
        })
        
      })
    },
    openEditingPlayerProfile () {
      this.editThisProfile({ playerProfile: this.drilldownPlayer.details,
                             playerClientData: this.drilldownPlayer.client })
      this.showEditProfileDialog = true
    },
    deletePlayerProfile () {
      this.editThisProfile({ playerProfile: this.drilldownPlayer.details,
                             playerClientData: this.drilldownPlayer.client })
      this.$confirm('고객을 리스트에서 삭제하겠습니까?', {
        confirmButtonText: '삭제',
        cancelButtonText: '취소',
        type: 'warning'
      }).then(() => {
        this.deleteProfile().then(() => {
          this.sidepanelClose()
        })
      })
    },
  }
}
</script>

<style lang='scss' scoped>
button.profile-edit-button {
  @apply py-2 px-8 text-sm rounded bg-gray-900 text-white opacity-75 cursor-pointer;
}

button.profile-edit-button:hover {
  @apply opacity-100 shadow-md;
}

button.profile-edit-button:disabled,
button.profile-edit-button[disabled]{
  @apply opacity-25 cursor-not-allowed;
}

</style>
